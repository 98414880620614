







import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Prop } from 'vue-property-decorator'
import Dropdown, { DropdownOption } from '@/components/Controls/Dropdown.vue'
import { Services } from 'truemarket-modules/src/services'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'

@Component({
  components: {
    ValidationProvider,
    Dropdown
  }
})
export default class Parking extends Vue {
  @Prop({ required: true })
  public value!: string

  onInput (newVal: string) {
    this.$emit('input', newVal)
  }

  mounted () {
    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Parking).then((vals) => {
      this.carParkOptions = vals.map((v) => ({ label: v.Label, value: v.ValueId }))
    })
  }

  carParkOptions: DropdownOption[] = []
}
